body, html {
  color: #555;
}

* {
  outline-color: #00d1b2;
}

a.list-item {
  text-decoration: none;
  color: #333;
}

a:hover {
  color: #111;
}
/* HACK: since react routers Link tag is incompatible with Bulmas list */
.list a.list-item:hover {
  background: rgba(0,0,0, 0.04) !important;
  font-weight: bold;
}

h2 {
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 2em;
}

.hide-until-parent-hovered {
  display: none;
}

a:hover .hide-until-parent-hovered { 
  display: inline;
}

#root > .container {
  /* HACK: Was hard to make distance to top navbar */
  margin-top: 6em;
}

nav.navbar.has-background-light {
  background: #00a78e !important;
  color: white !important;
}

.toolbar {
  display: flex;
  justify-content: left;
  align-items: center;
}

.toolbar > * {
  margin-right: 20px;
}

.record_group {
  border-bottom: solid 1px #eee;
}

.record_group:last-child {
  margin-bottom: 300px;
}

.record_group .fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.field_group {
  flex: 0 1 31%;
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 10px;
  background: #eee;
  padding: 2px;
  border-radius: 3px;
}

.field_group.focused {
  background: #00d1b2;
}
.field_group.focused .label {
  /* color: white; */
  font-weight: bold;
}
.field_group.focused.valid .label {
  color: white;
}

.field_group.double-entry-error {
  background: #ff0000;
}

.field_group.double-entry-error .label {
  color: white;
}

.field_group .label {
  font-size: 0.8em;
  font-weight: normal;
  padding-left: 5px;
  padding-right: 5px;
}

.field_group.unknown:not(.focused) {
  opacity: 0.5;
}

.field_group.incomplete {
  background: #ffbe68;
}

.field_group.invalid {
  background: #ff9999;
  color: #ff0000;
}

.field_group.focused.invalid {
  background: #ff0000;
}

.field_group.focused.invalid .label {
  color: #ffffff;
}

.field_group.focused.invalid input {
  border: none;
}

.field_group.incomplete input:focus,
.field_group.incomplete select:focus {
  border-color: #ffbe68;
  outline-color: #ffbe68;
}


.record_field .field {
  margin: 0 !important;
}


.record_field .field .control, .record_field  .field .select {
  width: 100%;
}

.record_field  .field .select select {
  width: 100%;
}

/* .record_field input, .record_field select {
  width: 100%;
  font-size: 1.0em;
  border: solid 1px #ddd;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
} */

input.input, .select select {
  outline-style:none;
  box-shadow:none;
  border-color: transparent;
}
input.input:focus, .select select:focus {
  border-color: #00d1b2;
}

.field input::placeholder {
  padding: 3px;
}

.field .radios {
  background: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.field .radios label.radio {
  flex: 0 1 auto;
  margin: 0px;
  font-size: 0.8em;
  padding: 3px;
}

.field .radios label.radio:focus-within {
  background: #00d1b248;
  border-radius: 3px;
}


.field .radios label.radio.selected {
  font-weight: bold;
}


.field .radios label input {
  margin-right: 5px;
}

.descriptions {
  display: none;
  padding: 5px;
  font-size: 0.7em;
  font-style: italic;
}

.descriptions.show {
  display: inline-block;
}


.field_help {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 20px;
  background: #00d1b2;
  color: white;
  font-weight: bold;
  border-top: solid 1px;
  opacity: 0;

  transition: opacity 0.125s;
}

.field_help.visible {
  opacity: 1;
}

.field_help.valid-record {
  bottom: 75px !important;
  
}

.finalize-entry {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #00a78e;
  padding: 20px;

}


.errors .error,
.warnings .warning {
  display: block;
  padding: 5px;
  border-radius: 5px;
  font-weight: normal;
  margin-bottom: 1px;
}

.errors .error {
  background: #ff0000;
  color: #ffffff;
}

.warnings .warning {
  background: #ffbe68;
  color: #ffffff;
}

.fileUploader {
  display: inline-block;
}



.list .hits {
  margin-left: 10px;
  color: #aaa;
}

.list .hits::before {
  /* font-style: italic; */
  /* content: 'Matches: '; */
}

.list .hits span {
  display: inline-block;
  margin-right: 0.5em;
  background: #aaa;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 2px;
  border-radius: 1em;
}

.search {
  position: relative;
  display: flex;
  margin-bottom: 5px;
}

.search input {
  margin-right: 5px;
  padding-left: 2em;
}

.search > .fa.fa-search {
  position: absolute;
  left: 10px;
  top: 8px;
  z-index: 99;
}

.search > .search-field {
  max-width: 200px;
  margin-right: 6px;
}

.list .list-item {
  display: flex;
}

.list .list-item > * {
  flex: 1;
}

.list .list-item .pid {
  max-width: 30%;
}

.list .list-item .hits {
  max-width: 50%;
}

.list .list-item .sort-field {
  max-width: 20%;
  font-style: italic;
  text-align: right;
  margin-right: 10px;
}

.list .list-item:hover .button {
  opacity: 1;
}

.list .list-item .button {
  opacity: 0;
  max-width: 3em;
}

.sort {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sort > * {
  margin-right: 10px;
}

.sort .select {
  max-width: 200px;
  margin-right: 10px;
}

.paging {
  display: flex;
  margin-right: 10px;
  align-items: center;
}

.paging > * {
  flex: 1;
  width: 2em;
}

.paging button {
  width: 10px !important;
  padding-left: 4px;
  padding-right: 4px;
}

.paging input {
  text-align: right;
  margin-right: 5px;
  padding-top: 4px;
  border: none;
  font-size: 16px !important;
  font-family: Roboto, sans-serif;
}

.paging span {
  font-size: 16px !important;
  font-family: Roboto, sans-serif;
  margin-right: 8px;
}

span.issues {
  color: #ff0000;
  font-size: 0.75em;
}

span.issues .fa {
  margin-right: 3px;
}


@media print {
  body {
    font-size: 0.6em;
  }

  input, select, .select, .input {
    border: none !important;
  }

  input.input, 
  .select > select {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: auto;
  }

  .select select {
    font-size: 0.8em !important;
  }

  input.input, .control.radios label {
    font-size: 64% !important;
  }

  .control .select select::after {
    display: none !important;
  }

  .navbar, .button, button, h1.title, .toolbar {
    display: none;
  }

  #root > .container {
    margin-top: 0;
  }

  .record_group {
    border-bottom: none;
    page-break-inside: avoid;
  }

  .record_group .title {
    font-size: 1em;
    margin: 2em 0 1em 0;
  }

  .record_group .fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: auto;
  }

  .fields .field_group {
    flex: 0 1 33%;
    width: auto;
    border: solid 1px black;
    color: black;
    line-height: 1.2;
    padding-top: 2px;
    padding-bottom: 2px;
    min-height: 42px;
  }

  .record_field {
  }

  .field_group,
  .field_group.incomplete, 
  .field_group.field_group.focused, 
  .field_group.invalid, 
  .field_group.unknown,
  .field_group.unknown:not(.focused) {
    background: white;
    margin: 0px;
    margin-right: -1px;
    margin-bottom: -1px;
    border-radius: 0;
    opacity: 1.0;
  }

  .field_group .label {
    font-weight: bold;
    margin-bottom: 0;
  }
}